@-webkit-keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

section.projectsSection {
  padding: 20px 0 200px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 100px;
  place-items: center;
}
.projects {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: Center;
  grid-gap: 50px;
  margin: 0;
  padding: 100px 50px;
}
.projects .sectionHeading {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
}
section div .doneHomework {
  font-size: 80px;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.158);
  text-align: center;
  line-height: 0.8em;
}
div h4.checkProjects {
  font-weight: 900;
  background: rgb(37, 84, 135);
  background: linear-gradient(
    90deg,
    rgba(37, 84, 135, 1) 0%,
    rgba(93, 200, 219, 1) 20%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 80px;
  line-height: 0.8em;
  -webkit-animation: vibrate-3 0.5s linear infinite both;
  animation: vibrate-3 0.5s linear infinite both;
  text-align: center;
}
.sectionText {
  max-width: 500px;
  min-width: 350px;
}
.sectionText p {
  color: white;
  font-size: 20px;
  line-height: 24.6px;
  letter-spacing: 0.6px;
}

.ProjectsGroup {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(410px, auto));
  grid-gap: 40px;
  width: 100%;
  padding: 0 100px;
}
.ProjectsGroup .project {
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
}
.ProjectsGroup .project img {
  width: 100%;
}
.ProjectsGroup .project h1 {
  font-size: 30px;
}
.ProjectsGroup .project h2 {
  font-size: 17px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.344);
  padding: 0;
  margin-bottom: 30px;
}
.ProjectsGroup .project a {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-decoration: none;
  color: white;
  padding: 12px 30px;
  border: 4px solid #5dc8db;
  transition: all 0.1s ease-in;
}
.ProjectsGroup .project a:hover {
  background-color: #5dc8db;
  border: 4px solid #5dc8db;
}
@media (max-width: 1246px) {
  section div .doneHomework {
    font-size: 70px;
  }
  div h4.checkProjects {
    font-size: 70px;
  }
}
@media (max-width: 1101px) {
  .projects {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: Center;
    justify-items: center;
    align-content: center;
    grid-gap: 90px;
    margin: 0;
    padding: 50px 30px;
    max-width: 640px;
  }
}
@media (max-width: 1014px) {
  .ProjectsGroup {
    grid-template-columns: repeat(auto-fit, minmax(350px, auto));
  }
}
@media (max-width: 945px) {
  .projects {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: Center;
    justify-items: center;
    align-content: center;
    margin: 0;

    max-width: 640px;
  }
  .sectionHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 587px) {
  section div .doneHomework {
    font-size: 50px;
  }
  div h4.checkProjects {
    font-size: 50px;
  }
}
@media (max-width: 555px) {
  .projects {
    padding: 50px 10px;
    max-width: 640px;
  }
  .ProjectsGroup {
    grid-template-columns: repeat(auto-fit, minmax(310px, auto));
    padding: 0;
  }
  .ProjectsGroup .project h1 {
    font-size: 30px;
  }
  .ProjectsGroup .project h2 {
    font-size: 17px;
  }
}
@media (max-width: 398px) {
  section div .doneHomework {
    font-size: 45px;
  }
  div h4.checkProjects {
    font-size: 45px;
  }
}
