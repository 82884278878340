.NavBar {
  background-color: black;
  width: 100vw;
  min-height: 50px;
  position: sticky;
  top: 0;
  z-index: 999;
  font-family: "Titillium Web", sans-serif;
  padding: 0 20px;
  box-shadow: 0px 49px 57px 14px rgba(0, 0, 0, 0.9);
}
.NavBar nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.NavBar nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.NavBar nav ul li {
  display: inline-block;
  margin: 20px;
}
.NavBar nav ul li a {
  text-decoration: none;
}
.NavBar ul:first-of-type a {
  color: #5dc8db;
  font-size: 42px;
  font-style: italic;
  font-weight: 900;
  line-height: 1rem;
}
.NavBar ul:last-of-type a {
  margin-left: 45px;
  font-size: 17px;
}
.NavBar ul:last-of-type a:hover {
  border-bottom: 4px solid #5dc8db;
}
.NavBar .MobileNavigation {
  display: none;
}
@media (max-width: 850px) {
  .NavBar .Navigation {
    display: none;
  }
  .NavBar .MobileNavigation .Hamburger,
  .NavBar .MobileNavigation .CloseHamburger {
    position: absolute;
    right: 5%;
    cursor: pointer;
    align-self: center;
  }

  .NavBar .MobileNavigation {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  .NavBar .MobileNavigation .logo {
    margin: 0;
    padding: 0;
    /* border: 1px solid purple; */
  }
  .NavBar .MobileNavigation .logo a {
    color: #5dc8db;
    font-size: 42px;
    font-style: italic;
    font-weight: 900;
    margin: 0;
    padding: 0;
  }
  .NavBar .MobileNavigation .logo a:hover {
    border: none;
  }
  .NavBar .MobileNavigation .MobileNavLinks {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 90px;
    left: 0%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.855);
    border-top: 4px solid #5dc8db;
  }
}
