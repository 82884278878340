@keyframes scrollDownAnimation {
  0% {
    transform: translate(0, 0) scale(1);
  }

  50% {
    transform: translate(0, 15px) scale(1.1);
  }

  100% {
    transform: translate(0, 0) scale(1);
  }
}
* {
  font-family: "Titillium Web", sans-serif;
  box-sizing: border-box;
}
section.mainHero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 87vh;
}

section.mainHero h1 {
  text-align: center;
  line-height: 10rem;
  font-size: 11.5em;
  letter-spacing: -2px;
  font-weight: 900;
  background-color: white;
  color: transparent;
  text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}
section.mainHero h1 .group,
section.mainHero h2 .group {
  display: flex;
  justify-content: center;
}
section.mainHero h2 div {
  padding: 0 3px;
}
section.mainHero h2 {
  font-size: 2.5rem;
  font-weight: 300;
  padding-top: 5px;
}
section.mainHero .animationScroll {
  position: absolute;
  bottom: 30px;
}
section.mainHero .scrollDown {
  animation: scrollDownAnimation 2s ease-out infinite both;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 920px) {
  section.mainHero h1 {
    line-height: 7.5rem;
    font-size: 9em;
  }
  section.mainHero h2 {
    font-size: 2rem;
    padding-top: 5px;
  }
}
@media (max-width: 850px) {
  section.mainHero {
    height: 84vh;
  }
}
@media (max-width: 695px) {
  section.mainHero h1 {
    line-height: 5rem;
    font-size: 6em;
  }
}
@media (max-width: 450px) {
  section.mainHero h1 {
    line-height: 4.5rem;
    font-size: 5.3em;
  }
}
@media (max-width: 370px) {
  section.mainHero h1 {
    line-height: 3.5rem;
    font-size: 4em;
  }
}
